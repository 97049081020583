"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _pluralize = _interopRequireDefault(require("pluralize"));
var _purchaseOrder = require("@/api/purchaseOrder");
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    // vSelect,
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        listAll: true,
        page: 1,
        perPage: 10,
        limit: 10,
        supplierId: null,
        suppliers: null,
        rawMaterialId: this.$route.params.id,
        poDate: null,
        storeId: null,
        search: null,
        fromTime: '',
        toTime: ''
      },
      total: 0,
      rawMaterial: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },
    fetchData() {
      this.listLoading = true;
      (0, _purchaseOrder.getList)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
        if (response.rawMaterial) {
          this.rawMaterial = response.rawMaterial;
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },
    checkDeliveryAdress(id) {
      const purchaseOrder = this.list.find(purchaseOrder => purchaseOrder.uuid === id);
      if (!purchaseOrder.deliveryAddress && purchaseOrder.status === 'Confirmed') {
        return _elementUi.MessageBox.confirm('This PO doesn\'t contain delivery Address (You can add in in detail page).<br />Do you still want to download?', 'Confirm', {
          confirmButtonText: _i18n.default.t('Supplier.Confirm'),
          cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
          dangerouslyUseHTMLString: true,
          type: 'warning'
        });
      }
      return Promise.resolve();
    },
    downloadPurchaseOrder(id, displayId) {
      this.checkDeliveryAdress(id).then(() => (0, _purchaseOrder.downloadPurchaseOrder)(id)).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    gotoGoodReceived(uuid) {
      this.$router.push(`/good-received-notes/edit/` + uuid);
    }
  }
};
exports.default = _default;