var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.rawMaterial,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c("div", {
            staticClass: "value",
            domProps: { innerHTML: _vm._s(_vm.ordercode) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "value",
            domProps: { innerHTML: _vm._s(_vm.itemSKU) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("包裝:" + _vm._s(_vm.rawMaterial.packing)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [_vm._v("C/NO:")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }