var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", gutter: 10 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24, md: 13, lg: 18 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: _vm.device !== "mobile",
                        "label-position":
                          _vm.device === "mobile" ? "top" : "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("route.Supplier") } },
                        [
                          _c("el-autocomplete", {
                            staticClass: "inline-input",
                            attrs: {
                              "fetch-suggestions": _vm.fetchSupplier,
                              placeholder: "请输入内容",
                              "value-key": "label",
                              clearable: "",
                            },
                            on: {
                              clear: _vm.clearSupplier,
                              select: _vm.handleSelectSupplier,
                            },
                            model: {
                              value: _vm.supplierName,
                              callback: function ($$v) {
                                _vm.supplierName = $$v
                              },
                              expression: "supplierName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("PurchaseRequests.Category") },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.categories,
                              label: "name",
                              reduce: function (category) {
                                return category.uuid
                              },
                            },
                            model: {
                              value: _vm.listQuery.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "categoryId", $$v)
                              },
                              expression: "listQuery.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("WarehouseArea.Warehouse") } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.warehouses,
                              label: "name",
                              reduce: function (warehouse) {
                                return warehouse.uuid
                              },
                            },
                            model: {
                              value: _vm.listQuery.rawMaterialWarehouseId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.listQuery,
                                  "rawMaterialWarehouseId",
                                  $$v
                                )
                              },
                              expression: "listQuery.rawMaterialWarehouseId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.query },
                        },
                        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24, md: 11, lg: 6 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: true,
                        "label-position":
                          _vm.device === "mobile" ? "top" : "right",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.fetchData($event)
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          attrs: {
                            type: "flex",
                            justify: _vm.device === "mobile" ? "start" : "end",
                            gutter: 10,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "5px" } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.listQuery.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "keyword", $$v)
                                      },
                                      expression: "listQuery.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "default",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.query },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" },
            },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--success",
                      class: { "is-disabled": _vm.isOffline },
                      attrs: { to: _vm.isOnline ? "/raw-materials/add" : "#" },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus" }),
                      _vm._v(" " + _vm._s(_vm.$t("route.AddRawMaterial"))),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "row-class-name": _vm.getRowClassName,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SKU" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.sku) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name"), width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.nameEng) +
                        " " +
                        _vm._s(scope.row.nameChin) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("route.Supplier") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.supplier ? scope.row.supplier.name : null
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.availableStock"), width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.available_stock) +
                        _vm._s(scope.row.unit) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.totalStock"), width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.total_stock) +
                        _vm._s(scope.row.unit) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Packing", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.packing) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("Order.Actions"),
              "min-width": "94px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary is-plain",
                          size: _vm.device === "mobile" ? "mini" : "default",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.printPacking(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v("Print Packing")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--success is-plain",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/raw-materials/" + scope.row.uuid + "/warehouses",
                        },
                      },
                      [_vm._v("Warehouse")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary is-plain",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/raw-materials/" +
                            scope.row.uuid +
                            "/purchase-orders",
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-time" }),
                        _vm._v(" Purchase History"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--warning",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: { to: "/raw-materials/edit/" + scope.row.uuid },
                      },
                      [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _vm._v(" Edit"),
                      ]
                    ),
                    _vm._v(" "),
                    !!_vm.user.roles.find(function (role) {
                      return ["Admin", "Procurement Manager"].includes(
                        role.name
                      )
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.isOffline,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRawMaterial(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.user.roles.find(function (role) {
                      return ["Admin", "Procurement Manager"].includes(
                        role.name
                      )
                    }) && scope.row.active === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "info",
                              plain: "",
                              disabled: _vm.isOffline,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.hideRawMaterial(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Hide")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.user.roles.find(function (role) {
                      return ["Admin", "Procurement Manager"].includes(
                        role.name
                      )
                    }) && scope.row.active === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              disabled: _vm.isOffline,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.activeRawMaterial(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Reactive")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }