"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _humanizeDuration = _interopRequireDefault(require("humanize-duration"));
var _vuex = require("vuex");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _user = require("@/api/user");
var _store = require("@/api/store");
var _report = require("@/api/report");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import { MessageBox, Message } from 'element-ui'
var _default = {
  data() {
    return {
      uuid: this.$route.params.id,
      list: null,
      listLoading: false,
      users: [],
      stores: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        userId: null,
        storeId: null,
        groupBy: 'month',
        page: 1,
        limit: 9999
      },
      groupByOptions: [{
        key: 'date',
        name: 'Day'
      }, {
        key: 'week',
        name: 'Week'
      }, {
        key: 'month',
        name: 'Month'
      }, {
        key: 'year',
        name: 'Year'
      }],
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'user'])
  },
  async created() {
    const today = new Date();
    this.listQuery['fromTime'] = new Date(today.getFullYear(), today.getMonth(), 1);
    this.listQuery['toTime'] = new Date(today.getFullYear(), today.getMonth() + 1, -1);
    this.listQuery['toTime'].setDate(this.listQuery.toTime.getDate() + 1);
    this.fetchData();
    this.fetchUsers();
    this.fetchStores();
  },
  methods: {
    humanizeDuration(time) {
      return (0, _humanizeDuration.default)(time * 1000);
    },
    async fetchUsers() {
      (0, _user.getUsers)({
        limit: 999,
        roles: ['staff.parttime', 'staff']
      }).then(response => {
        this.users = response.data;
      });
    },
    async fetchStores() {
      (0, _store.getStores)({
        limit: 999
      }).then(response => {
        this.stores = response.data;
      });
    },
    async fetchData() {
      const param = {};
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = this.listQuery[key];
          param[key] = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds();
        } else {
          param[key] = this.listQuery[key];
        }
      }
      this.listLoading = true;
      (0, _report.getUserLate)({
        ...param
      }).then(response => {
        this.list = response.data;
        this.total = response.data ? response.data.length : 0;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    getSummaries() {
      const summary = {};
      let totalLateTime = 0;
      let totalLateCount = 0;
      let totalOTTime = 0;
      let totalOTCount = 0;
      let lateTimeCount = 0;
      let lateCountCount = 0;
      let OTTimeCount = 0;
      let OTCountCount = 0;
      if (this.list) {
        this.list.forEach(row => {
          if (row.lateTime) {
            totalLateTime += row.lateTime;
            lateTimeCount++;
          }
          if (row.lateCount) {
            totalLateCount += row.lateCount;
            lateCountCount++;
          }
          if (row.oTTime) {
            totalOTTime += row.oTTime;
            OTTimeCount++;
          }
          if (row.oTCount) {
            totalOTCount += row.oTCount;
            OTCountCount++;
          }
        });
      }
      summary[3] = _i18n.default.t('Order.Total') + ': ' + this.humanizeDuration(totalLateTime) + ' | Avg: ' + this.humanizeDuration((lateTimeCount ? totalLateTime / lateTimeCount : 0).toFixed(0));
      summary[4] = _i18n.default.t('Order.Total') + ': ' + totalLateCount + ' | Avg: ' + (lateCountCount ? totalLateCount / lateCountCount : 0).toFixed(0);
      summary[5] = _i18n.default.t('Order.Total') + ': ' + this.humanizeDuration(totalOTTime) + ' | Avg: ' + this.humanizeDuration((OTTimeCount ? totalOTTime / OTTimeCount : 0).toFixed(0));
      summary[6] = _i18n.default.t('Order.Total') + ': ' + totalOTCount + ' | Avg: ' + (OTCountCount ? totalOTCount / OTCountCount : 0).toFixed(0);
      return summary;
    }
  }
};
exports.default = _default;