"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _rawMaterial = require("@/api/rawMaterial");
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PriceDialog',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    rawMaterialId: {
      type: String,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      first: {
        uuid: null
      },
      isActive: false,
      rawMaterialSchedulePriceChange: {
        limit: 10,
        rawMaterialId: this.rawMaterialId,
        startDate: null,
        price: null
      },
      rules: {
        startDate: {
          required: true,
          message: 'Start Date is required',
          trigger: 'blur'
        },
        price: {
          required: true,
          message: 'Price is required'
        }
      },
      total: 0
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogFormVisible;
      },
      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }
    },
    perPage: {
      get() {
        return this.listQuery.limit;
      }
    }
  },
  watch: {
    perPage: function (val) {
      this.rawMaterialSchedulePriceChange.perPage = val;
    }
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // this.listQuery.uuid = this.$route.params.rawMaterialId
      const filter = {
        limit: this.listQuery.limit,
        page: this.listQuery.page,
        rawMaterialId: this.rawMaterialId
      };
      this.listLoading = true;
      (0, _rawMaterial.getRawMaterialScheduledPrices)(filter).then(response => {
        this.list = response.data;
        if (response.first && response.first.uuid) {
          this.first = response.first;
        }
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        (0, _rawMaterial.createRawMaterialScheduledPrice)(this.rawMaterialSchedulePriceChange).then(response => {
          (0, _elementUi.Message)({
            message: 'Raw Material scheduled price change created.',
            type: 'success',
            duration: 5 * 1000
          });
          this.rawMaterialSchedulePriceChange = {
            ...this.rawMaterialSchedulePriceChange,
            startDate: null,
            price: null
          };
          if (response.page) {
            this.listQuery.page = response.page;
          }
          this.$emit('complete', response.data);
          this.fetchData();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          this.fetchData();
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    searchDate() {
      const filter = {
        limit: this.listQuery.limit,
        page: this.listQuery.page,
        startDate: this.rawMaterialSchedulePriceChange.startDate,
        rawMaterialId: this.rawMaterialId
      };
      this.listLoading = true;
      (0, _rawMaterial.getRawMaterialScheduledPrices)(filter).then(response => {
        if (response.data.length) {
          this.list = response.data;
          this.total = response.meta.total;
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },
    deleteRawMaterialScheduledPrice(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('RawMaterialScheduledPrice.ConfirmDeleteMessage'), _i18n.default.t('RawMaterialScheduledPrice.Confirm'), {
        confirmButtonText: _i18n.default.t('RawMaterialScheduledPrice.Delete'),
        cancelButtonText: _i18n.default.t('RawMaterialScheduledPrice.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _rawMaterial.deleteRawMaterialScheduledPrice)(id).then(response => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('RawMaterialScheduledPrice.Recorddeleted'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$emit('complete', response.data);
          this.fetchData();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.UpdateFailed'),
            type: 'error',
            duration: 5 * 1000
          });
          // console.log(err)
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }
  }
};
exports.default = _default;