"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _user = require("@/api/user");
var _elementUi = require("element-ui");
var _errorParse = require("@/utils/error-parse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ResetPassword',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!(0, _validate.notEmptyString)(value)) {
        callback(new Error('Please enter email'));
      } else {
        callback();
      }
    };
    const checkConfirmPassword = (rule, value, callback) => {
      if (this.resetForm.password !== '') {
        if (value === '') {
          callback(new Error('Please confirm the password'));
        } else {
          if (value !== this.resetForm.password) {
            callback(new Error('Password mismatch'));
          }
          callback();
        }
      }
    };
    return {
      resetForm: {
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
        token: this.$route.query.token
      },
      resetRules: {
        email: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        password: [{
          required: true,
          message: 'Password is required',
          trigger: 'blur'
        }, {
          min: 8,
          message: 'Password can not be less than 8 digits',
          trigger: 'blur'
        }],
        password_confirmation: {
          validator: checkConfirmPassword,
          trigger: 'blur'
        }
      },
      loading: false,
      redirect: undefined
    };
  },
  /* beforeRouteEnter(to, from, next) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      // return "Android";
    }
     // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = location.hash.substr(1)
    }
  }, */
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.resetForm.validate(valid => {
        if (valid) {
          this.loading = true;
          (0, _user.resetPassword)(this.resetForm).then(response => {
            (0, _elementUi.Message)({
              message: response.msg,
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push({
              path: '/'
            });
          }).catch(err => {
            // console.log(err)

            if (err.headers && err.headers['retry-after']) {
              (0, _elementUi.Message)({
                message: 'Too Many Attempt, Please Retry After ' + err.headers['retry-after'] + 's',
                type: 'error',
                duration: 5 * 1000
              });
            } else if (err) {
              (0, _elementUi.Message)({
                message: (0, _errorParse.parseError)(err) || 'failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            }
            this.loading = false;
          });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    }
  }
};
exports.default = _default;