"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _rawMaterial = require("@/api/rawMaterial");
var _warehouse = require("@/api/warehouse");
var _store = require("@/api/store");
var _category = require("@/api/category");
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data() {
    return {
      loading: false,
      rawMaterial: {
        id: null,
        sku: null,
        unit: null,
        nameEng: null,
        nameChin: null,
        supplier: null,
        stores: [],
        warehouses: [],
        categories: [],
        active: 'true',
        supplierId: null,
        subUnitQty: 1,
        subUnit: null
      },
      rules: {
        sku: {
          required: true,
          message: 'SKU is required'
        },
        unit: {
          required: true,
          message: 'Unit is required'
        },
        // supplierId: { required: true, message: 'Supplier is required' },
        nameEng: {
          required: true,
          message: 'Name is required'
        }
      },
      allStoreChecked: false,
      allWarehouseChecked: false,
      stores: [],
      supplierName: null,
      supplierSelectedName: null,
      warehouses: [],
      categories: [],
      dialogPriceFormVisible: false,
      keepAliveDialogPriceFormVisible: false,
      buttonText: 'Add'
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    itemSKU: function () {
      return this.rawMaterial.sku.replaceAll('--', '<br />');
    },
    ordercode: function () {
      return this.$route.query.ordercode;
    }
  },
  watch: {},
  async created() {
    (0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
    (0, _category.getCategories)({
      perPage: 9999
    }).then(categories => {
      this.categories = categories.data;
    });
    /* getSuppliers({ perPage: 9999 }).then(suppliers => {
      this.suppliers = suppliers.data
      this.suppliers.unshift({ name: '', uuid: null })
    }) */
    const warehouses = await (0, _warehouse.getWarehouses)({
      limit: 100
    });
    this.warehouses = warehouses.data;
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      await this.fetchData();
    } else {
      const mainStore = this.warehouses.find(warehouse => warehouse.name === 'main');
      this.rawMaterial.warehouses.push(mainStore.id);
      this.buttonText = 'Add';
      this.allStoreChecked = true;
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _rawMaterial.getRawMaterial)(this.$route.params.id).then(response => {
        this.rawMaterial = {
          ...response.data,
          stores: response.data.stores.map(store => store.id),
          warehouses: response.data.warehouses.map(warehouse => warehouse.id),
          categories: response.data.categories.map(category => category.id),
          supplierId: response.data.supplier.uuid
        };
        this.supplierName = this.rawMaterial.supplier.name;
        this.supplierSelectedName = this.rawMaterial.supplier.name;
      }).finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          setTimeout(() => {
            window.print();
            window.close();
          }, 1000);
        });
      });
    }
  }
};
exports.default = _default;