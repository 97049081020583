"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _goodReceivedNote = require("@/api/goodReceivedNote");
var _warehouse = require("@/api/warehouse");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10,
        warehouse_id: ''
      },
      stores: [],
      total: 0
    };
  },
  computed: {
    dateFilter: {
      get() {
        return [this.listQuery.fromTime, this.listQuery.toTime];
      },
      set(dateFilter) {
        if (dateFilter != null) {
          this.listQuery.fromTime = dateFilter[0];
          this.listQuery.toTime = dateFilter[1];
        } else {
          this.listQuery.fromTime = '';
          this.listQuery.toTime = '';
        }
      }
    },
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    (0, _warehouse.getWarehouses)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _goodReceivedNote.getSummary)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    }
  }
};
exports.default = _default;