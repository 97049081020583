"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _rawMaterial = require("@/api/rawMaterial");
var _warehouseAreaRawMaterial = require("@/api/warehouseAreaRawMaterial");
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    // vSelect,
  },
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        perPage: 10,
        limit: 10,
        listAll: true,
        supplierId: null,
        suppliers: null,
        rawMaterialId: this.$route.params.id,
        poDate: null,
        storeId: null,
        search: null,
        fromTime: '',
        toTime: ''
      },
      warehouseAreaRawMaterial: {
        id: '',
        name: null,
        warehouseAreaId: null,
        rawMaterialId: null
      },
      total: 0,
      rawMaterial: ''
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _rawMaterial.getRawMaterial)(this.$route.params.id).then(response => {
        this.rawMaterial = response.data;
      });
      (0, _warehouseAreaRawMaterial.getWarehouseAreaRawMaterials)({
        rawMaterialId: this.$route.params.id,
        perPage: 9999,
        hasStock: true
      }).then(response => {
        this.list = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.listLoading = false;
      });
    },
    getSummaries() {
      const summary = {};
      let totalStock = 0;
      if (this.list) {
        this.list.forEach(row => {
          totalStock += row.qty;
        });
      }
      summary[2] = _i18n.default.t('Order.Total') + ' ' + totalStock.toFixed(2) + ' ' + this.rawMaterial.subUnit;
      return summary;
    }
  }
};
exports.default = _default;